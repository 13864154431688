// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAc_YvDXGFEnAPbjG1K8pFO5S30bpnohIQ",
    authDomain: "falcon-staging-295308.firebaseapp.com",
    projectId: "falcon-staging-295308",
    storageBucket: "falcon-staging-295308.appspot.com",
    messagingSenderId: "177570145153",
    appId: "1:177570145153:ios:07a4ce016c2ace1d0dfe80",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
