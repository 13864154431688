import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { FiMenu, FiX, FiUser, FiSettings, FiShoppingCart, FiShare2 } from 'react-icons/fi';

const Header = () => {
    const { currentUser } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/signin');
        } catch (error) {
            console.error("Failed to sign out", error);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="bg-white shadow-md sticky top-0 z-50">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between py-3">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center space-x-4">
                        <Link to="/" className="text-xl font-bold text-indigo-600">
                            Karsh
                        </Link>
                    </div>
                    <div className="sm:hidden">
                        <button onClick={toggleMenu} className="text-gray-700 text-2xl">
                            {menuOpen ? <FiX /> : <FiMenu />}
                        </button>
                    </div>
                </div>

                <nav className={`flex-col sm:flex-row sm:flex ${menuOpen && isMobile ? 'flex' : 'hidden'} w-full sm:w-auto sm:items-center sm:justify-end`}>
                    {currentUser ? (
                        <>
                            {isMobile ? (
                                <div className="relative">
                                    <button
                                        onClick={toggleMenu}
                                        className="mt-3 sm:mt-0 sm:ml-4 bg-indigo-600 text-white px-3 py-1 rounded hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                    >
                                        Menu
                                    </button>
                                    {menuOpen && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg">
                                            <Link
                                                to="/dashboard"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Dashboard
                                            </Link>
                                            <Link
                                                to="/profile"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Profile
                                            </Link>
                                            <Link
                                                to="/settings"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Settings
                                            </Link>
                                            <button
                                                onClick={handleLogout}
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex items-center space-x-4 sm:space-x-6">
                                    <FiUser className="text-gray-700 text-2xl hover:text-indigo-600 cursor-pointer" />
                                    <FiSettings className="text-gray-700 text-2xl hover:text-indigo-600 cursor-pointer" />
                                    <FiShoppingCart className="text-gray-700 text-2xl hover:text-indigo-600 cursor-pointer" />
                                    <FiShare2 className="text-gray-700 text-2xl hover:text-indigo-600 cursor-pointer" />
                                    <button
                                        onClick={handleLogout}
                                        className="bg-indigo-600 text-white px-4 py-1 rounded-md shadow-md hover:bg-indigo-500 transition duration-200"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center space-x-4 sm:space-x-6">
                            <Link to="/signin" className="text-indigo-600 hover:text-indigo-800 px-4 py-2 rounded-md transition duration-200 whitespace-nowrap">
                                Sign In
                            </Link>
                            <Link to="/signup" className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500 transition duration-200 whitespace-nowrap">
                                Sign Up
                            </Link>
                        </div>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Header;
