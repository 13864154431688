import React from 'react';
import { FaTools } from 'react-icons/fa';
import Header from '../Header'; // Import the Header

const Home = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-purple-500 to-indigo-600">
            <Header /> {/* Use the updated Header */}
            <main className="flex flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                <div className="text-center bg-white p-10 rounded-xl shadow-lg max-w-md w-full">
                    <FaTools className="text-indigo-600 text-6xl mx-auto mb-6" />
                    <h1 className="text-3xl font-bold text-gray-900 mb-4">We're Under Construction</h1>
                    <p className="text-gray-600 mb-6">
                        Our website is currently under construction. We are working hard to give you a better experience.
                    </p>
                    <p className="text-gray-500 text-sm">
                        Please check back soon. Thank you for your patience!
                    </p>
                </div>
            </main>
        </div>
    );
};

export default Home;
