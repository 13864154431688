import React from 'react';
import { auth, googleProvider, appleProvider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc'; // Google Icon
import { FaApple } from 'react-icons/fa'; // Apple Icon
import Header from '../Header'; // Import the Header

const Signup = () => {
    const navigate = useNavigate();

    const signUpWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    };

    const signUpWithApple = async () => {
        try {
            await signInWithPopup(auth, appleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header /> {/* Use the Header */}
            <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-600 px-4 py-12 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold text-gray-900">Create Your Account</h2>
                    </div>

                    <button
                        onClick={signUpWithGoogle}
                        className="w-full flex items-center justify-center bg-white border border-gray-300 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <FcGoogle className="text-xl mr-2" />
                        Sign Up with Google
                    </button>

                    <button
                        onClick={signUpWithApple}
                        className="w-full flex items-center justify-center bg-black py-2 px-4 text-sm font-medium text-white shadow-sm rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <FaApple className="text-xl mr-2" />
                        Sign Up with Apple
                    </button>

                    <p className="text-gray-600 text-sm text-center mt-6">
                        Already have an account?{' '}
                        <a href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Log in
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Signup;
