import React from 'react';
import { FcGoogle } from 'react-icons/fc'; // Google Icon
import { FaApple } from 'react-icons/fa'; // Apple Icon
import { auth, googleProvider, appleProvider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Header from '../Header'; // Import the Header

export default function SignIn() {
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    };

    const signInWithApple = async () => {
        try {
            await signInWithPopup(auth, appleProvider);
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header /> {/* Use the Header */}
            <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-600 px-4 py-12 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
                    <div className="text-center">
                        <img
                            alt="Your Company"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            className="mx-auto h-12 w-auto"
                        />
                        <h2 className="mt-6 text-3xl font-bold text-gray-900">
                            Welcome to Project Karsh
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Sign in to your account
                        </p>
                    </div>

                    <form className="mt-8 space-y-6">
                        {/* Email and Password fields */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <button
                                        type="button"
                                        onClick={() => alert('Redirect to forgot password')}
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        Forgot your password?
                                    </button>
                                </div>
                            </div>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                    <div className="mt-6">
                        <button
                            onClick={signInWithGoogle}
                            className="flex w-full justify-center items-center rounded-md bg-white border border-gray-300 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            <FcGoogle className="text-xl mr-2" />
                            Sign in with Google
                        </button>
                    </div>

                    <div className="mt-4">
                        <button
                            onClick={signInWithApple}
                            className="flex w-full justify-center items-center rounded-md bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            <FaApple className="text-xl mr-2" />
                            Sign in with Apple
                        </button>
                    </div>

                    <p className="mt-8 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <button
                            type="button"
                            onClick={() => alert('Redirect to sign-up page')}
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            Start your 14-day free trial
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}
