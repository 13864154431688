import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';
import Header from '../Header'; // Import the shared Header component

export default function Dashboard() {
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const { data, error } = await supabase
                .from('users')
                .select('*')
                .eq('email', currentUser.email)
                .single();

            if (error) {
                console.error("Error fetching user data:", error);
            } else {
                setUserData(data);
            }
        };

        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser]);

    if (!currentUser) {
        return <Navigate to="/signin" />;
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <Header /> {/* Use the updated Header */}
            <main className="flex-1">
                {/* Profile Section */}
                <div className="bg-gradient-to-r from-purple-500 to-indigo-600 py-6 sm:py-8">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
                        <div className="flex justify-center">
                            <img
                                src="https://via.placeholder.com/100"
                                alt="User Profile"
                                className="h-20 sm:h-24 w-20 sm:w-24 rounded-full border-4 border-white shadow-lg"
                            />
                        </div>
                        <div className="mt-4">
                            <h1 className="text-2xl sm:text-3xl font-bold text-white">{userData?.name || 'User'}</h1>
                            <p className="text-indigo-200">{currentUser?.email}</p>
                            <p className="text-indigo-100 mt-2">{userData?.bio || 'No bio available'}</p>
                        </div>
                    </div>
                </div>

                {/* Tab Navigation */}
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-8">
                    <div className="border-b border-gray-200">
                        <nav className="flex space-x-4 sm:space-x-8">
                            <Link
                                to="/wishlist"
                                className="text-indigo-600 border-indigo-600 py-4 px-2 border-b-2 font-medium text-base sm:text-lg transition duration-200"
                            >
                                Wishlist
                            </Link>
                            <Link
                                to="/gifters"
                                className="text-gray-500 border-transparent py-4 px-2 border-b-2 font-medium text-base sm:text-lg transition duration-200 hover:text-indigo-600 hover:border-indigo-600"
                            >
                                Gifters
                            </Link>
                        </nav>
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
                    <div className="bg-white rounded-lg shadow-lg p-6 sm:p-10 text-center">
                        <div className="flex justify-center items-center">
                            <div className="bg-indigo-500 text-white p-4 sm:p-6 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 sm:h-12 w-10 sm:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c.48 0 .86-.4.86-.9 0-.5-.38-.9-.86-.9a.87.87 0 00-.86.9c0 .5.38.9.86.9zm0 8c-.48 0-.86.4-.86.9 0 .5.38.9.86.9.48 0 .86-.4.86-.9 0-.5-.38-.9-.86-.9z" />
                                </svg>
                            </div>
                        </div>
                        <p className="mt-4 sm:mt-6 text-lg sm:text-xl font-semibold text-gray-900">No Gifts Yet</p>
                        <p className="mt-2 sm:mt-4 text-gray-600">This wishlist seems to be empty. Click below to add gifts.</p>
                        <button className="mt-6 sm:mt-8 px-6 sm:px-8 py-2 sm:py-3 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-500 transition duration-200">
                            + Add Gifts
                        </button>
                        <div className="mt-4 sm:mt-6">
                            <button className="px-4 sm:px-6 py-2 sm:py-3 bg-white border border-gray-300 text-gray-700 rounded-md shadow-md hover:bg-gray-100 transition duration-200">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 sm:h-5 w-4 sm:w-5 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h4l3 10h8l3-10h4M14 10V6a4 4 0 00-8 0v4M18 14h.01M6 14h.01M18 10v10a2 2 0 11-4 0v-4a2 2 0 11-4 0v4a2 2 0 11-4 0V10h10z" />
                                </svg>
                                Import from Amazon
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
